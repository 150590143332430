import React from "react"
import styled from "styled-components"
import classNames from "classnames"

import Icon from "../icon"
import Loader from "./Loader"

const Button = ({
  text,
  icon,
  type = "button",
  disabled = false,
  loading = false,
  surface = false,
  primary = false,
  onClick = () => {},
}) => {
  if (primary && surface) {
    console.error("Use only one parameter to style Button: surface or primary")
  }

  const btnClasses = classNames({
    "--surface": surface && !primary,
    "--primary": primary && !surface,
    "--loading": loading,
  })

  return (
    <Btn
      type={type}
      disabled={loading || disabled}
      className={btnClasses}
      onClick={onClick}
    >
      {icon && <Icon name={icon} />}
      {text && <span className="text">{text}</span>}
      {loading && <Loader />}
    </Btn>
  )
}

export default Button

const Btn = styled.button`
  padding: 0;
  font-size: 1.125rem;
  font-family: var(--headingfont);
  transition: all 0.3s;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: var(--btn-border-color, transparent);
  background: var(--btn-background, transparent);
  color: var(--btn-color, currentColor);

  &:hover {
    border-color: var(--btn-on-hover-border-color, transparent);
    background: var(--btn-on-hover-background, transparent);
    color: var(--btn-on-hover-color, currentColor);
  }

  &.--surface {
    --btn-border-color: var(--color-on-surface);
    --btn-background: var(--color-surface);
    --btn-color: var(--color-on-surface);
    --btn-on-hover-border-color: var(--color-primary);
    --btn-on-hover-background: var(--color-primary);
    --btn-on-hover-color: var(--color-on-primary);
    /* --loader-color: 34, 34, 34; */
  }

  &.--primary {
    --btn-border-color: var(--color-primary);
    --btn-background: var(--color-primary);
    --btn-color: var(--color-on-primary);
    --btn-on-hover-border-color: var(--color-primary);
    --btn-on-hover-background: var(--color-primary);
    --btn-on-hover-color: var(--color-on-primary);
    /* --loader-color: 34, 34, 34; */
  }

  &.--loading {
    --btn-color: transparent;
    --btn-on-hover-color: transparent;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2.5rem;
    line-height: 1;
    .icon ~ & {
      padding-left: 0.5rem;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    padding: 0.9rem 1rem;
  }

  [disabled] {
    opacity: 0.6;
  }
`
