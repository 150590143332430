import React from "react"
import styled from "styled-components"

const Loader = () => {
  return (
    <LoaderWrapper>
      <div className="loader"></div>
    </LoaderWrapper>
  )
}

export default Loader

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .loader,
  .loader:after {
    --loader-size: 35px;
    --loader-color: 255, 255, 255;
    border-radius: 50%;
    width: var(--loader-size);
    height: var(--loader-size);
  }

  .loader {
    margin: auto;
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(var(--loader-color), 0.2);
    border-right: 0.5em solid rgba(var(--loader-color), 0.2);
    border-bottom: 0.5em solid rgba(var(--loader-color), 0.2);
    border-left: 1.1em solid rgba(var(--loader-color), 1);
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    border-width: 0.5em;
    top: calc(50% - var(--loader-size) / 2);
    left: calc(50% - var(--loader-size) / 2);
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
