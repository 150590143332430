import { useLazyQuery } from "@apollo/react-hooks"
import { loader } from "graphql.macro"

const gql = loader("./gql/queryPosts.graphql")

export const useQueryPosts = () => {
  const [trigger, { loading, error, data }] = useLazyQuery(gql)

  const response = data ? data.posts.data : null
  return [{ loading, error, response }, trigger]
}
