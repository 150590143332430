import React from "react"
import styled from "styled-components"

import Button from "./Button"

const LoadMore = ({ isShow, loading, onClick }) => {
  return (
    <LoadMoreWrapper>
      {isShow && (
        <Button
          text="Загрузить еще"
          loading={loading}
          onClick={onClick}
          surface
        />
      )}
    </LoadMoreWrapper>
  )
}

export default LoadMore

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem var(--offset-one-side) 3rem;
  /* button {
    width: 15.5rem;
  } */
`
